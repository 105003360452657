import React from 'react';
import PropTypes from 'prop-types';
import {
    Grid,
} from '@mui/material';
import ProjectPlan from "../ProjectPlan";

const View = (props) => {
    const {
        projectPlan,
        errorVisible,
        loading
    } = props;

    return (
        <Grid item xs={12}>
            <ProjectPlan loading={loading} projectPlan={projectPlan}/>
        </Grid>
    );

};

View.propTypes = {
    loading: PropTypes.bool,
    projectPlan: PropTypes.object,
    errorVisible: PropTypes.bool
};

export default View;