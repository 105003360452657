import React, {useState, useEffect, useCallback} from 'react';
import PropTypes from 'prop-types';
import {
    FormControl,
    Box,
    Typography,
    Grid,
    TextField,
    CssBaseline,
    Alert,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import HelpCenterSharpIcon from '@mui/icons-material/HelpCenterSharp';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

import {Formik, Form, FieldArray} from 'formik';
import {makeStyles} from 'tss-react/mui';
import ProjectPlanSchema from './Validation';
import {useGoogleReCaptcha} from 'react-google-recaptcha-v3'
import Stakeholders from './Stakeholders';

const useStyles = makeStyles()((theme) => {
    return {
        main: {},
        form: {},
        submit: {},
    }
});

const FormS = (props) => {
    const {submitFunction, sendingRequest, setRecaptcha, recaptcha, errorVisible, loading} = props;
    const {classes} = useStyles();
    const [didMount, setDidMount] = useState(false);
    const {executeRecaptcha} = useGoogleReCaptcha()

    const handleReCaptchaVerify = useCallback(async () => {
        if (!executeRecaptcha) {
            console.log('Execute recaptcha not yet available');
            return;
        }
        const recTokenResult = await executeRecaptcha('projectplan_form');
        await setRecaptcha(recTokenResult);
    }, [executeRecaptcha]);

    useEffect(() => {
        setDidMount(true)
    }, []);

    useEffect(() => {
        if (didMount) {
            handleReCaptchaVerify();
        }
    }, [handleReCaptchaVerify]);

    const initialValues = {
        stakeholders: [],
        description: ''
    };

    return (
        <main className={classes.main}>
            <CssBaseline/>
            <Grid container>
                <Grid item xs={12}>
                    <Typography variant="h5" component="h2" gutterBottom>Describe your project</Typography>
                    <Formik initialValues={initialValues} autoComplete="off" id="projectplan_form"
                            validateOnMount={true}
                            validateOnChange={true} validateOnBlur={true}
                            validationSchema={ProjectPlanSchema}
                            onSubmit={async (values) => {
                                handleReCaptchaVerify().then(() => {
                                    submitFunction(values);
                                })
                            }}
                    >
                        {({values, touched, errors, handleChange, handleBlur, isValid}) => (
                            <Form>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <Typography component="span" variant="body2" color="gray">
                                            <HelpCenterSharpIcon style={{verticalAlign: "middle", marginRight: "5px"}}
                                                                 fontSize="inherit"/>
                                            Describe your project and get your AI-generated milestones and tasks for all
                                            stakeholders.
                                        </Typography>
                                        <FormControl margin="normal" required fullWidth>
                                            <TextField
                                                //className={classes.field}
                                                multiline rows={4}
                                                required
                                                variant="standard"
                                                label="Description"
                                                name="description"
                                                value={values.description}
                                                helperText={
                                                    touched.description && errors.description
                                                        ? errors.description
                                                        : "e.g. We want to build a new B2B software product"
                                                }
                                                error={Boolean(touched.description && errors.description)}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} mt={4}>
                                        <Typography component="span" variant="h5">
                                            Stakeholders
                                        </Typography>
                                        <Box mt={1}>
                                            <Typography component="span" variant="body2" color="gray">
                                                <HelpCenterSharpIcon
                                                    style={{verticalAlign: "middle", marginRight: "5px"}}
                                                    fontSize="inherit"/>
                                                You can describe all the stakeholders involved in the project and they
                                                will automatically get the respective tasks assigned.
                                            </Typography>
                                        </Box>

                                        <Grid container>
                                            <Grid item xs={12}>
                                                <FieldArray name="stakeholders">
                                                    {({insert, remove, push}) => (
                                                        <Stakeholders values={values}
                                                                      errors={errors}
                                                                      touched={touched}
                                                                      push={push}
                                                                      remove={remove}
                                                                      handleChange={handleChange}
                                                                      handleBlur={handleBlur}/>
                                                    )}
                                                </FieldArray>
                                            </Grid>
                                        </Grid>
                                        {/**/}
                                    </Grid>

                                    <Grid item xs={12} mt={4}>
                                        <LoadingButton
                                            type="submit"
                                            variant="contained"
                                            color="primary"
                                            size="large"
                                            className={classes.submit}
                                            disabled={!isValid || loading}
                                            loading={sendingRequest || loading}
                                        >
                                            Create your projectplan
                                        </LoadingButton>
                                    </Grid>
                                    <Grid item xs={12}>
                                        {errorVisible && <Alert severity="error">Something went wrong :(</Alert>}
                                    </Grid>
                                </Grid>
                            </Form>
                        )}
                    </Formik>
                </Grid>
            </Grid>
        </main>
    );
};

FormS.propTypes = {
    sendingRequest: PropTypes.bool,
    projectPlan: PropTypes.object,
    refreshRecaptcha: PropTypes.bool,
    recaptcha: PropTypes.string,
    setRecaptcha: PropTypes.func,
    description: PropTypes.string,
    errorVisible: PropTypes.bool,
    loading: PropTypes.bool
};

export default FormS;