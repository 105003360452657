import React from 'react';
import PropTypes from 'prop-types';
import {
    Chip,
    Box
} from '@mui/material';

const Assignees = (props) => {

    const {subtask_id, assignees} = props;

    return (
        <Box spacing={2}>
            {assignees.map((assignee, i) => {
                return <Chip label={assignee}
                             key={`assignees-${subtask_id}-${i}`}
                             size="small"
                             style={{ marginRight: "5px", marginBottom: "5px" }}
                             color="primary"
                />
            })}
        </Box>
    );
};


Assignees.propTypes = {
    subtask_id: PropTypes.string,
    assignees: PropTypes.arrayOf(PropTypes.string)
};

export default Assignees;

