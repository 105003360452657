import React from 'react';
import PropTypes from 'prop-types';
import {
    Box,
    Typography,
    Grid,
    Paper
} from '@mui/material';
import FormS from "./Form";
import ProjectPlan from "../ProjectPlan";

const Page = (props) => {
    const {
        sendingRequest,
        projectPlan,
        projectPlanHash,
        refreshRecaptcha,
        recaptcha,
        submitFunction,
        setRecaptcha,
        errorVisible,
        loading
    } = props;

    return (
        <Grid container mt={5}>
            <Grid item xs={12}>
                <Paper sx={{backgroundColor: '#f8f8f8', boxShadow: 3}}>
                    <Grid container>
                        <Grid item xs={12}>
                            <Box mt={5} mb={2} display="flex"
                                 style={{justifyContent: "center", flexDirection: "column"}}>
                                <img style={{
                                    width: "100px",
                                    marginLeft: "auto",
                                    marginRight: "auto",
                                    marginBottom: "20px"
                                }}
                                     src={process.env.PUBLIC_URL + "bubble.png"}/>
                            </Box>
                            <Typography variant="h4" component="h1" align="center" gutterBottom color="primary">Project planning on
                                autopilot</Typography>
                        </Grid>
                        <Grid item xs={12} p={4}>
                            <FormS sendingRequest={sendingRequest}
                                   submitFunction={submitFunction}
                                   projectPlan={projectPlan}
                                   recaptcha={recaptcha}
                                   setRecaptcha={setRecaptcha}
                                   errorVisible={errorVisible}
                                   loading={loading}
                            />
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
            <Grid item xs={12}>
                <ProjectPlan loading={loading} projectPlan={projectPlan} projectPlanHash={projectPlanHash}/>
            </Grid>
        </Grid>
    );

};

Page.propTypes = {
    sendingRequest: PropTypes.bool,
    projectPlan: PropTypes.object,
    projectPlanHash: PropTypes.string,
    recaptcha: PropTypes.string,
    setRecaptcha: PropTypes.func,
    submitFunction: PropTypes.func,
    errorVisible: PropTypes.bool,
    loading: PropTypes.bool
};

export default Page;