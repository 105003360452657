import React from 'react';
import PropTypes from 'prop-types';
import {
    Chip,
} from '@mui/material';

const Files = (props) => {

    const {subtask_id, files} = props;

    return (
        files.map((file, i) => {
            return <Chip label={file}
                         key={`files-${subtask_id}-${i}`}
                         size="small"
                         style={{ marginRight: "5px", marginBottom: "5px" }}
            />
        }));

};


Files.propTypes = {
    subtask_id: PropTypes.string,
    files: PropTypes.arrayOf(PropTypes.string)
};

export default Files;

