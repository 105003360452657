import React from 'react';
import PropTypes from 'prop-types';
import {
    Typography,
    Grid,
    LinearProgress,
    Link
} from '@mui/material';
import ShareIcon from '@mui/icons-material/Share';
import Milestone from './Milestone';


const ProjectPlan = (props) => {

    const {projectPlan,projectPlanHash, loading} = props;

    return (
        <Grid container py={5}>
            {(projectPlan === undefined && loading === true) ?
                <Grid item xs={12} mt={5}>
                    <Typography align="center">This can take some time ...</Typography>
                    <LinearProgress/>
                </Grid> : null
            }

            {(projectPlan !== undefined) ?
                <React.Fragment>
                <Grid item xs={12}>
                    <Typography variant="h5" gutterBottom>Your project plan</Typography>
                </Grid>
                <Grid item xs={12}>

                    <Typography variant="body1"><ShareIcon fontSize="inherit"/> Share your project plan:&nbsp;
                        <Link rel="noopener, noreferrer" target="_blank"
                              href={`https://projectplan.aws.munichresearch.com/view/${projectPlanHash}`}>
                              https://projectplan.aws.munichresearch.com/view/{projectPlanHash}
                        </Link>
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Grid container>
                        {projectPlan.milestones?.map((milestone, index) => {
                            return (
                                <Milestone key={`milestone-${index}`} milestone={milestone}/>
                            );
                        })}
                    </Grid>
                </Grid>
                </React.Fragment>: null}
        </Grid>)
}

ProjectPlan.propTypes = {
    loading: PropTypes.bool,
    projectPlan: PropTypes.object,
    projectPlanHash: PropTypes.string

};

export default ProjectPlan;

