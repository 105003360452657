import * as Yup from "yup";

const ProjectPlanSchema = Yup.object().shape({
    description: Yup.string()
        .min(10, 'Add some more details to the project description :-)')
        .max(2000, 'Up to 2000 characters.')
        .required('Required'),
    stakeholders: Yup.array()
        .of(
            Yup.object().shape({
                company: Yup.string().max(250, 'Should not be more than 250 characters').nullable(),
                role: Yup.string().max(250, 'Should not be more than 250 characters').nullable(),
                name: Yup.string().max(250, 'Should not be more than 250 characters').nullable(),
            })
        ),
});

export default ProjectPlanSchema;