import './App.css';
import {createTheme, ThemeProvider} from '@mui/material/styles';
import Container from '@mui/material/Container';
import {purple, deepPurple} from '@mui/material/colors';
import {Route, Routes} from "react-router-dom"
import ScrollToTop from "./components/ScrollToTop/ScrollToTop";
import FormContainer from './containers/FormContainer';
import ViewContainer from './containers/ViewContainer';


const prussianBlue = {
    50: "#e2eff2",
    100: "#b8d7e2",
    200: "#90becf",
    300: "#6ba5bd",
    400: "#5193b2",
    500: "#3a83a7",
    600: "#2e779d",
    700: "#22678d",
    800: "#18577c",
    900: "#0b3c5d"
};

const blue1 = {
    50: "#e3f6fb",
    100: "#b7e6f6",
    200: "#8bd6f0",
    300: "#65c6ea",
    400: "#4eb9e6",
    500: "#40ade3",
    600: "#3a9fd4",
    700: "#328dc1",
    800: "#2e7cad",
    900: "#245c8b"
}


const blue = {
    50: "#e8eafc",
    100: "#c5cbf6",
    200: "#9ca9f0",
    300: "#7187eb",
    400: "#4c6ce6",
    500: "#2751da",
    600: "#2048cf",
    700: "#123ec2",
    800: "#0433b5",
    900: "#001f9f"
}


const theme = createTheme({
    palette: {
        background: {
            default: "#eef0f4"
        },
        text: {
            primary: "#1D2731"
        },
        primary: deepPurple,
        secondary: purple
    }, components: {
        MuiPaper: {
            styleOverrides: {
                root: {
                    borderRadius: 10
                }
            }
        }}
});

function App() {
    return (
        <ThemeProvider theme={theme}>
            <ScrollToTop/>
            <div className="App">
                <Container>
                    <Routes>
                        <Route path="/" element={<FormContainer/>}/>
                        <Route path="/view/:projectplan_hash" element={<ViewContainer/>}/>
                        <Route path="*" element={<FormContainer/>}/>
                    </Routes>
                </Container>

            </div>
        </ThemeProvider>
    );
}

export default App;
