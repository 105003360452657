import React from 'react';
import PropTypes from 'prop-types';
import {
    Box,
    Button,
    Grid, IconButton, TextField, Paper, FormHelperText, Typography
} from '@mui/material';

import {getIn} from "formik";
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from "@mui/icons-material/Add";

const Stakeholders = (props) => {

    const {values, touched, errors, push, remove, handleChange, handleBlur} = props;

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Grid container>
                    {values.stakeholders.length > 0 &&
                        values.stakeholders.map((stakeholder, index) => {

                            const company = `stakeholders[${index}].company`;
                            const touchedCompany = getIn(touched, company);
                            const errorCompany = getIn(errors, company);

                            const role = `stakeholders[${index}].role`;
                            const touchedRole = getIn(touched, role);
                            const errorRole = getIn(errors, role);

                            const name = `stakeholders[${index}].name`;
                            const touchedName = getIn(touched, name);
                            const errorName = getIn(errors, name);

                            return (
                                <Grid item xs={12} md={8} key={`stakeholder-${index}`} mt={2}
                                      justifyContent="start" alignContent="row">
                                    <Grid container>
                                        <Grid item xs={12} md={1}>
                                            <Box sx={{ display: 'flex', alignItems: 'center', height: "100%" }}>
                                            <Typography variant="body2" style={{fontWeight: "bold"}}>
                                                {index+1}.
                                            </Typography>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} md={10} >
                                            <Grid container spacing={2}>
                                                <Grid item xs={12} md={4}>
                                                    <TextField
                                                        id={company}
                                                        autoComplete='off'
                                                        fullWidth
                                                        //className={classes.field}
                                                        variant="standard"
                                                        label="Company"
                                                        name={company}
                                                        //value={stakeholder.company}
                                                        helperText={
                                                            touchedCompany && errorCompany
                                                                ? errorCompany
                                                                : index === 0 ? "e.g. ACME Corp." : ""
                                                        }
                                                        error={Boolean(touchedCompany && errorCompany)}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        margin="normal"
                                                    />
                                                </Grid>
                                                <Grid item xs={12} md={4}>
                                                    <TextField
                                                        id={role}
                                                        fullWidth
                                                        autoComplete='off'
                                                        //className={classes.field}
                                                        variant="standard"
                                                        label="Role"
                                                        name={role}
                                                        //value={stakeholder.role}
                                                        helperText={
                                                            touchedRole && errorRole
                                                                ? errorRole
                                                                : index === 0 ? "e.g. CTO, Head of marketing" : ""
                                                        }
                                                        error={Boolean(touchedRole && errorRole)}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        margin="normal"
                                                    />
                                                </Grid>
                                                <Grid item xs={12} md={4}>
                                                    <TextField
                                                        id={name}
                                                        fullWidth
                                                        autoComplete='off'
                                                        //className={classes.field}
                                                        variant="standard"
                                                        label="Name"
                                                        name={name}
                                                        //value={stakeholder.name}
                                                        helperText={
                                                            touchedName && errorName
                                                                ? errorName
                                                                : index === 0 ? "e.g. Roger Rabbit" : ""
                                                        }
                                                        error={Boolean(touchedName && errorName)}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        margin="normal"
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12} md={1}
                                              style={{display: "flex", alignItems: "center", justifyContent: "end"}}>
                                            <IconButton aria-label="delete" color="primary"
                                                        onClick={() => remove(index)}
                                                        size="small">
                                                <DeleteIcon fontSize="inherit"/>
                                            </IconButton>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            );
                        })}
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Button onClick={() => push({company: '', role: '', name: ''})}
                        variant="contained"
                        startIcon={<AddIcon/>}>
                    Add
                </Button>
            </Grid>
        </Grid>
    );
};


Stakeholders.propTypes = {
    values: PropTypes.object,
    touched: PropTypes.object,
    errors: PropTypes.object,
    push: PropTypes.func,
    remove: PropTypes.func,
    handleChange: PropTypes.func,
    handleBlur: PropTypes.func
};

export default Stakeholders;

