import React from 'react';
import PropTypes from 'prop-types';
//import {connect} from 'react-redux';
//import {withRouter} from 'react-router-dom';
import axios from 'axios';
import { useParams } from "react-router-dom"
import View from '../components/View';

class ViewContainer extends React.Component {

    constructor(props) {
        super(props);
        this.projectplan_hash = this.props.params.projectplan_hash;

        this.state = {
            loading: false,
            projectPlanHash: undefined,
            projectPlan: undefined,
            errorVisible: false
        };
    }

    getProjectPlan = async (projectplan_hash) => {
        var config = {
            method: 'get',
            url: 'https://prod.projectplan-api.aws.munichresearch.com/projectplan/' + projectplan_hash,
        };
        return axios(config)
            .then((response) => {
                const payload = response.data;
                if (payload.status === "failed") {
                    this.setState({projectPlanHash: undefined, loading: false});
                    this.showError();
                } else if (payload.status === "success") {
                    this.setState({projectPlanHash: undefined, loading: false, projectPlan: payload.projectplan});
                }
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {
                this.setState({loading: false});
            });
    }

    componentDidMount() {
        this.setState({loading: true});
        this.getProjectPlan(this.projectplan_hash);
    }

    render() {
        return (
            <View
                projectPlan={this.state.projectPlan}
                errorVisible={this.state.errorVisible}
                loading={this.state.loading}/>
        );
    }
}

ViewContainer.propTypes = {
    projectplan_hash: PropTypes.string
};

export default (props) => (
    <ViewContainer
        {...props}
        params={useParams()}
    />
);