import React from 'react';
import PropTypes from 'prop-types';
import {Box, Grid, Paper, Typography} from "@mui/material";
import Subtask from "./Subtask";
import Divider from "@mui/material/Divider";

const Milestone = (props) => {

    const {milestone} = props;

    return (
        <Grid item xs={12} my={2} key={`milestone-${milestone.milestone_id}`}>
            <Box component={Paper} elevation={1} p={4}>
                <Typography variant="h6" component="h2"
                            gutterBottom>{milestone.milestone_id}. {milestone.description}</Typography>
                <Grid container>
                    {milestone.subtasks?.map((subtask, index) => {
                        return (
                            <React.Fragment key={`milestone-${milestone.milestone_id}-${index}-fragment`}>
                                <Subtask subtask={subtask}/>
                                {(index < milestone.subtasks?.length - 1) ?
                                    <Grid item xs={12} key={`milestone-${milestone.milestone_id}-${index}-divider-container`}>
                                        <Divider key={`milestone-${milestone.milestone_id}-${index}-divider`}/>
                                    </Grid> : null}
                            </React.Fragment>
                        );
                    })
                    }
                </Grid>
            </Box>
        </Grid>
    );
}

Milestone.propTypes = {
    milestone: PropTypes.object
};

export default Milestone;

