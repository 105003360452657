import React from 'react';
import PropTypes from 'prop-types';
//import {connect} from 'react-redux';
//import {withRouter} from 'react-router-dom';
import axios from 'axios';
import Page from "../components/Form/Page";

class FormContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            fetched: true,
            projectPlanHash: undefined,
            projectPlan: undefined,
            sendingRequest: false,
            recaptcha: undefined,
            errorVisible: false
        };
    }

    cleanStakeholders = (stakeholders) => {
        if (Array.isArray(stakeholders)) {
            return stakeholders.filter(function (stakeholder) {
                return !(!stakeholder.company && !stakeholder.role && !stakeholder.name);
            });
        } else {
            return [];
        }
    }

    submitRequest = async (payload) => {
        var data = JSON.stringify(payload);
        var config = {
            method: 'post',
            url: 'https://prod.projectplan-api.aws.munichresearch.com/projectplan/',
            headers: {
                'Content-Type': 'application/json',
            },
            data: data,
        };
        this.setState({
            sendingRequest: true,
            errorVisible: false,
            projectPlanHash: undefined,
            projectPlan: undefined,
            loading: true
        });
        return axios(config).then((response) => {
            const res = response.data;
            this.setState({projectPlanHash: res.projectplan_hash, fetched: false});
        })
            .catch((error) => {
                this.setState({errorVisible: true, fetched: true});
                console.log(error);
            })
            .finally(() => {
                this.setState({sendingRequest: false});
            });
    }


    getProjectPlan = async (projectplan_hash) => {
        var config = {
            method: 'get',
            url: 'https://prod.projectplan-api.aws.munichresearch.com/projectplan/' + projectplan_hash,
        };
        return axios(config)
            .then((response) => {
                const payload = response.data;
                if (payload.status === "failed") {
                    this.setState({projectPlanHash: undefined, loading: false, fetched: true});
                    this.showError();
                } else if (payload.status === "success") {
                    this.setState({loading: false, projectPlan: payload.projectplan, fetched: true});
                }
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {});
    }

    setRecaptcha = (recaptcha) => {
        this.setState({
            recaptcha: recaptcha
        });
        console.log("got captcha");
    }

    submitFunction = (data) => {
        data.stakeholders = this.cleanStakeholders(data.stakeholders);
        data.recaptcha = this.state.recaptcha;
        this.submitRequest(data);
        return data;
    }


    componentWillUnmount() {
        this.timer = null;
    }

    componentDidMount() {
        this.timer = setInterval(() => {
            if (this.state.projectPlanHash && !this.state.fetched) {
                this.setState({loading: true});
                this.getProjectPlan(this.state.projectPlanHash);
            } else {
                this.setState({loading: false});
            }
        }, 5000);
    }

    render() {
        return (
            <Page
                sendingRequest={this.state.sendingRequest}
                projectPlanHash={this.state.projectPlanHash}
                projectPlan={this.state.projectPlan}
                recaptcha={this.state.recaptcha}
                setRecaptcha={this.setRecaptcha}
                submitFunction={this.submitFunction}
                errorVisible={this.state.errorVisible}
                loading={this.state.loading}/>
        );
    }
}

FormContainer.propTypes = {};

export default FormContainer;