import React from 'react';
import PropTypes from 'prop-types';
import {Grid, Typography, Checkbox} from '@mui/material';
import Assignees from "./Assignees";
import Files from "./Files";
import Divider from '@mui/material/Divider';

const Subtask = (props) => {

    const {subtask} = props;

    return (
        <Grid item py={2} pl={1} xs={12} key={`subtask-${subtask.subtask_id}`}>
            <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start">
                <Grid item xs={11} pl={2}>
                    <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start">
                        <Grid item xs={12}>
                            <Typography variant="body1" component="span" style={{fontWeight: "bold"}} align="left">{subtask.description}</Typography>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Typography variant="subtitle1" component="span">Approx. duration: </Typography>
                            <Typography component="span">{subtask.duration}h</Typography>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Typography variant="subtitle1">Assignees: </Typography>
                            <Assignees subtask_id={subtask.subtask_id} assignees={subtask.assignees} />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="subtitle1">Documentation: </Typography>
                            <Files subtask_id={subtask.subtask_id} files={subtask.files}/>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={1}>
                    <Checkbox sx={{paddingTop: 0, float: 'right'}}/>
                </Grid>
            </Grid>
        </Grid>
    );
}


Subtask.propTypes = {
    subtask: PropTypes.object,
};

export default Subtask;
